import { useErrorBoundary } from "react-error-boundary";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";

interface Props {
  error: Error;
}

export function ErrorFallback({ error }: Props) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Flex align="center" justify="center" h="100vh" w="100vw">
      <Box
        role="alert"
        p={6}
        maxW="full"
        borderWidth={1}
        borderRadius="md"
        boxShadow="md"
        bg="white"
        color="red.400"
      >
        <Heading mb={2} fontSize="xl" fontWeight="medium" color="gray.800">
          Ops, algo deu errado...
        </Heading>
        <Text mb={4} fontSize="base" color="gray.600">
          {error.message}
        </Text>
        <Button
          type="button"
          onClick={resetBoundary}
          bg="primary"
          color="red.400"
          px={0}
          pb={2}
          pt={2.5}
          textTransform="uppercase"
          fontSize="xs"
          fontWeight="medium"
          _hover={{ bg: "primary" }}
        >
          Tentar novamente
        </Button>
      </Box>
    </Flex>
  );
}
