import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  PropsWithChildren,
  useEffect,
} from "react";
import api from "../services/api";
import { useAppToast } from "./ToastContext";
import { useHistory } from "react-router-dom";
import jwtDecode, { JwtPayload } from "jwt-decode";

export interface AuthState {
  token: string;
  user: object;
}

export interface CompaniesProps {
  idCompany: number;
  idClient: number;
  cnpjClient: string;
  company: {
    id: number;
    cnpj: string;
    databaseHost: string;
    corporateName: string;
    fantasyName: string;
    license: {
      expirationDate: Date;
    };
    userActive: "S" | "N";
    notification: {
      welcome: null;
    };
  };
}

interface CompanySelected {
  companyID: CompaniesProps["idCompany"];
  userID: CompaniesProps["idClient"];
}

export interface SignInCredenticals {
  cnpj: string;
  password: string;
  type: string;
  companySelected?: CompanySelected;
}

interface AuthContextData {
  user: object | any;
  isTemporaryPassword: boolean;
  updateTokenAfterConfirmedEmail(): Promise<boolean>;
  signIn({
    cnpj,
    password,
    type,
    companySelected,
  }: SignInCredenticals): Promise<void>;
  updateTokenClient({ data }: any): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { toast } = useAppToast();
  const history = useHistory();

  const [companies, setCompanies] = useState<CompaniesProps[]>([]);
  const [isTemporaryPassword, setIsTemporaryPassword] = useState(false);
  const [payload, setPayload] = useState<AuthState>(() => {
    const token = localStorage.getItem("@cmp:token");
    const user = localStorage.getItem("@cmp:user");
    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });

  interface UserParams {
    hasEmail: boolean;
    isTemporaryPassword: boolean;
    isValidEmail: boolean;
    isConfirmedEmail: boolean;
    isFirstAccess: boolean;
    isSupportPassword: boolean;
  }

  const updateTokenClient = useCallback(async ({ data }: any) => {
    const firstToken = localStorage.getItem("@cmp:token");
    const user = localStorage.getItem("@cmp:user");

    if (firstToken == null || user == null) {
      signOut();
      return;
    }

    try {
      await updateToken(firstToken, data);
      return;
    } catch (error) {
      toast.error(`Não foi atualizar o token! ${error}`);
    }
  }, []);

  async function updateTokenAfterConfirmedEmail(): Promise<boolean> {
    const firstToken = localStorage.getItem("@cmp:token");
    const user = localStorage.getItem("@cmp:user");

    if (firstToken == null || user == null) {
      signOut();
      return false;
    }

    const userParams = localStorage.getItem("@cmp:userParams");

    if (userParams) {
      const userParamsParsed = JSON.parse(userParams);
      userParamsParsed.isFirstAccess = false;
      userParamsParsed.isValidEmail = true;
      const updatedUserParams = JSON.stringify(userParamsParsed);
      localStorage.setItem("@cmp:userParams", updatedUserParams);
    }

    const companies: any = await fetchedCompanies(firstToken);
    setCompanies(companies);

    if (companies.length == 1) {
      const data = {
        companyID: companies[0].idCompany,
        userID: companies[0].idClient,
      };

      const response = await api.post(`customer/auth/update-token`, data, {
        headers: {
          Authorization: `Bearer ${firstToken}`,
        },
      });

      const { token, payload: user } = response.data;

      localStorage.setItem("@cmp:user", JSON.stringify(user));
      localStorage.setItem("@cmp:token", token);
      api.defaults.headers.authorization = `Bearer ${token}`;
      setPayload({ token, user });
      return true;
    }

    return false;

  };
  const signIn = useCallback(
    async ({ cnpj, password, type }: SignInCredenticals) => {
      const url = type === "customer" ? "/customer/auth" : "/business/auth";

      const data = {
        cnpj,
        password,
      };

      try {
        const response = await api.post(url, data);
        const {
          token,
          payload: user,
          isTemporaryPassword: temporary,
          hasEmail,
          isValidEmail,
          isConfirmedEmail,
          isFirstAccess,
          isTemporaryPassword,
          isSupportPassword,
        } = response.data;

        localStorage.setItem("@cmp:token", token);
        localStorage.setItem("@cmp:user", JSON.stringify(user));

        let userParams: UserParams = {
          hasEmail: hasEmail,
          isValidEmail: isValidEmail,
          isConfirmedEmail: isConfirmedEmail,
          isFirstAccess: isFirstAccess,
          isTemporaryPassword: isTemporaryPassword,
          isSupportPassword: isSupportPassword
        };

        if (type !== "customer") {
          setPayload({ token, user });
          return;
        }

        const companies: any = await fetchedCompanies(token);
        setCompanies(companies);

        setIsTemporaryPassword(temporary);

        const params: (keyof UserParams)[] = [
          "isTemporaryPassword",
          "isValidEmail",
          "isConfirmedEmail",
          "isFirstAccess",
          "isSupportPassword"
        ];

        for (const param of params) {
          if (eval(param) === false) {
            userParams[param] = false;
          }
        }

        localStorage.setItem("@cmp:userParams", JSON.stringify(userParams));

        if(isFirstAccess){
          setPayload({ token, user });
          history.push("/dashboard/confirm-mail");
          return;
        }

        if (companies.length == 1) {
          const data = {
            companyID: companies[0].idCompany,
            userID: companies[0].idClient,
          };

          return await updateToken(token, data);
        }
        history.push("/select-company");
        return;
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("CNPJ ou senha incorreta.");
        } else {
          if (error.message === "Network Error") {
            toast.error(
              `Desculpe, estamos enfrentando problemas técnicos no momento. Por favor, tente novamente mais tarde.`
            );
            return;
          }

          toast.error(`${error.message}`);
          throw error;
        }
      }

      return;
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("@cmp:token");
    localStorage.removeItem("@cmp:user");
    localStorage.removeItem("@cmp:type");
    localStorage.removeItem("@cmp:temporaryPassword");
    localStorage.removeItem("@cmp:userParams");

    setPayload({} as AuthState);
  }, []);

  async function fetchedCompanies(
    token: string
  ): Promise<CompaniesProps[] | undefined> {
    try {
      const response = await api.get("/customer/companies", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data;
    } catch (error: any) {
      toast.error(`${error.response.data.message}`);
      return;
    }
  }

  async function updateToken(tokenToUpdate: string, data: any): Promise<void> {
    try {
      const oldUserData = localStorage.getItem("@cmp:user");

      if (oldUserData) {
        const oldUserDataParsed = JSON.parse(oldUserData);
        const { isPasswordTemporary} = oldUserDataParsed;

        if (isPasswordTemporary === true) {
          toast.success("Login feito com senha temporária");
          localStorage.setItem("@temp-password", "true");
        }
      }
      const response = await api.post(`customer/auth/update-token`, data, {
        headers: {
          Authorization: `Bearer ${tokenToUpdate}`,
        },
      });

      const { token, payload: user } = response.data;

      localStorage.setItem("@cmp:user", JSON.stringify(user));
      localStorage.setItem("@cmp:token", token);
      api.defaults.headers.authorization = `Bearer ${token}`;
      toast.success("Login realizado com sucesso!");
      setPayload({ token, user });
    } catch (error) {
      toast.error(`Não foi possível atualizar o token!`);
      signOut();
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("@cmp:token");
    const storedIsTemporaryPassword = localStorage.getItem(
      "@cmp:temporaryPassword"
    );
    const currentDate = Date.now();

    let isTemporaryPassword = false;
    if (storedIsTemporaryPassword) {
      try {
        isTemporaryPassword = JSON.parse(storedIsTemporaryPassword);
      } catch (error) {
        console.error("Erro ao obter parâmetro de senha temporaria:", error);
      }
    }

    setIsTemporaryPassword(isTemporaryPassword);

    if (token) {
      const tokenObj = jwtDecode<JwtPayload>(token);

      if (tokenObj.exp) {
        const tokenExp = tokenObj.exp * 1000;
        if (tokenExp <= currentDate) {
          signOut();
          toast.error(`Acesso negado!`, "O seu acesso expirou!");
        }
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: payload.user,
        signIn,
        updateTokenAfterConfirmedEmail,
        signOut,
        updateTokenClient,
        isTemporaryPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
}
