export const TOKEN_KEY = "@cmp:token";
export const USER_CREDENTIALS = "@cmp:user";
export const TYPE = "@cmp:type";
export const TEMPORARYPASSWORD = "@cmp:temporaryPassword";
export const USERPARAMS = "@cmp:userParams";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = () => localStorage.getItem(USER_CREDENTIALS);
export const login = (token: string) => localStorage.setItem(TOKEN_KEY, token);
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_CREDENTIALS);
  localStorage.removeItem(TYPE);
  localStorage.removeItem(TEMPORARYPASSWORD);
  localStorage.removeItem(USERPARAMS);
};
