import Loading from "../../../assets/loading-truck.gif";

export default function GlobalLoading () {
  return (
<div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <center>
          {" "}
          <img src={Loading} alt="loading" width={"150px"} />{" "}
        </center>
      </div>
  )
}