import { Flex } from "@chakra-ui/react";

interface PageProps {
  children: React.ReactNode;
}

export function PageWithoutBackground({ children }: PageProps) {
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      maxH={"100vh"}
      flexDir="column"
      px={8}
      bg={"white"}
      borderRadius={4}
      shadow={"0 0 20px rgba(0, 0, 0, 0.05)"}
      flexGrow={1}
      flexShrink={0}
      overflow="auto"
    >
      {children}
    </Flex>
  );
}
