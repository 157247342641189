import React, { lazy } from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import { SelectCompany } from "../pages/Dashboard/Customer/SelectCompany";

const ChangePassword = lazy(() => import("../pages/ChangePassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const SignIn = lazy(() => import("../pages/SignIn"));
const VerifyCode = lazy(() => import("../pages/VerifyCode"));
const Unsubscribe = lazy(() => import("../pages/Unsubscribe"));
const EmailVerification = lazy(() => import("../pages/EmailVerification"));
const Parameters = lazy(() => import("../pages/Dashboard/common/Parameters"));

const Routes: React.FC = () => (
  <Switch>
    <Route exact path={"/"} component={SignIn} />
    <Route path={"/forgot-password"} component={ForgotPassword} />
    <Route path={"/email-verification/:code"} component={EmailVerification} />
    <Route path={"/verify-code"} component={VerifyCode} />
    <Route path={"/change-password"} component={ChangePassword} />
    <Route path={"/select-company"} component={SelectCompany} />
    <Route path={"/unsubscribe/:cnpj"} component={Unsubscribe} />
    <Route path={"/dashboard"} component={Dashboard} isPrivate />
  </Switch>
);

export default Routes;
