import { extendTheme } from '@chakra-ui/react';
import { config } from './config';

export const theme = extendTheme({
  config,
  colors: {
    brand: {
      "primary": '#b1cc7d',
      "secondary": '#b7d480',
      "gradient": '#d4dc57',
      "hover": '#C5D86D',
      "background": '#e2e8f0',
      "background-secondary": '#f7fafc',
      "background-third": '#F0F1F5',
    },
    button: {
      "primary": '#00D65C',
      "primary_hover" :'#019c44'
    },
    icon: {
      "total": '#53BF9D',
      "delivered": '#FFCA62',
      "cancelled": '#FA4169',
      "moveit" : '#636BD8',
    },
    blue: {
      100: '#636BD8',
      200: '#647AC7'
    },
    red: {
      100: '#FA4169',
      200: '#F75A68',
    },
    green: {
      100: '#53BF9D',
      200: '#b7d480',
    },
    gray: {
      700: '#1A181B',
      600: '#3E3A40',
      500: '#5F5B62',
      400: '#9F9BA1',
      300: '#D9D8DA',
      200: '#EDECEE',
      100: '#F7F7F8'
    }
  },
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
  },
  styles: {
    global: {
      body: {
        bg: 'gray.50'
      }
    }
  }
});
