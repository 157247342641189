import React, { useEffect } from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../context/AuthContext";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const currentLocation = location.pathname;

  useEffect(() => {
    const storedUser = localStorage.getItem("@cmp:user");
    const userParams = localStorage.getItem("@cmp:userParams");

    if (storedUser && userParams) {
      const { isTemporaryPassword, isValidEmail, isFirstAccess } =
        JSON.parse(userParams);
      const { companyID } = JSON.parse(storedUser);

      if(companyID === undefined && isFirstAccess === false &&
         isTemporaryPassword === false &&
        currentLocation !== "/dashboard/select-company"){
          history.push("/dashboard/select-company");
      }

      if (
        isTemporaryPassword === true &&
        currentLocation !== "/dashboard/update-password"
      ) {
        history.push("/dashboard/update-password");
      }

      if (
        (isFirstAccess === true || isValidEmail === false) &&
        currentLocation !== "/dashboard/confirm-mail"
      ) {
        history.push("/dashboard/confirm-mail");
      }

    }
  }, []);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "/dashboard",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
