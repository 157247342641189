import { BrowserRouter } from "react-router-dom";
import AppProvider from "./context";
import Routes from "./routes";
import { Flex, Text } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";
import GlobalLoading from "./components/Layout/GlobalLoading";
import { ErrorFallback } from "./components/Layout/ErrorFallback";

const App: React.FC = () => {
  const apiENV = process.env.REACT_APP_ENV;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<GlobalLoading />}>
        <AppProvider>
          <Routes />
        </AppProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
