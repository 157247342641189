import React, { PropsWithChildren, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AuthProvider } from "./AuthContext";
import { ToastProvider } from "./ToastContext";
import GlobalLoading from "../components/Layout/GlobalLoading";
import { ErrorFallback } from "../components/Layout/ErrorFallback";
import { BrowserRouter } from "react-router-dom";

const appProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <BrowserRouter>
    <ToastProvider>
      <AuthProvider>{children}</AuthProvider>
    </ToastProvider>
  </BrowserRouter>
);

export default appProvider;
