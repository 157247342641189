import { useEffect, useState } from "react";

import { Box, ButtonGroup, Flex, IconButton, Select } from "@chakra-ui/react";

import { useHistory } from "react-router-dom";

import { RiArrowGoBackLine } from "react-icons/ri";
import { useAuth } from "../../../../context/AuthContext";
import { useAppToast } from "../../../../context/ToastContext";
import api from "../../../../services/api";
import { PageWithoutBackground } from "../../../../components/Layout/PageWithoutBackground";
import { Button } from "../../../../components/Form/Button";

interface CompanyProps {
  cnpj: string;
  corporateName: string;
  databaseHost: string;
  fantasyName: string;
  id: number;
  license: {
    expirationDate: Date;
  };
  notification: {
    welcome: null;
  };
  userActive: "S" | "N";
}

interface CompaniesRequestProps {
  cnpjClient: string;
  idClient: number;
  idCompany: number;
  company: CompanyProps;
}

export const SelectCompany: React.FC = () => {
  const history = useHistory();
  const { signOut, updateTokenClient } = useAuth();
  const { toast } = useAppToast();

  const [companies, setCompanies] = useState<CompaniesRequestProps[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any | null>(null);
  const token = localStorage.getItem("@cmp:token");

  if (!token) {
    signOut();
    history.push("/");
  }

  async function getCompanies() {
    try {
      const response = await api.get("/customer/companies", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompanies(response.data.data);
    } catch (error: any) {
      if (error.response) {
        console.error(`Erro: ${error.response.data.message}`);
      } else {
        toast.error("Erro ao buscar empresas:", error.message);
      }
    }
  }

  useEffect(() => {
    if (token) {
      getCompanies();
    }
  }, []);

  async function onSubmit(e?: any) {
    e.preventDefault();

    try {
      const data = {
        companyID: selectedCompany.idCompany,
        userID: selectedCompany.idClient,
      };
      await updateTokenClient({ data });
    } catch (error) {
      toast.error(`Não foi possível realizar o login`);
      signOut();
    }
  }

  return (
    <>
      <PageWithoutBackground>
        <Flex
          as="form"
          w="100%"
          maxW={"xl"}
          margin={"0px auto"}
          h={"100vh"}
          bg={"white"}
          align={"center"}
          justify={"center"}
          flexDir="column"
          onSubmit={() => onSubmit()}
        >
          <Select
            isRequired
            width={"100%"}
            placeholder="Selecione uma empresa"
            value={selectedCompany?.id}
            onChange={(e) => {
              const companyId = e.target.value;
              setSelectedCompany(
                companies.find(
                  (company) => String(company.idCompany) === companyId
                ) || null
              );
            }}
          >
            {companies ? (
              companies.map((company) => {
                return (
                  <option key={company.company.id} value={company.idCompany}>
                    {company.company.corporateName}
                  </option>
                );
              })
            ) : (
              <option value="" disabled>
                Carregando empresas...
              </option>
            )}
          </Select>
          <ButtonGroup my={4} width={"100%"}>
            <IconButton
              onClick={signOut}
              variant="outline"
              colorScheme="green"
              aria-label="Go back"
              icon={<RiArrowGoBackLine />}
            />
            <Button onClick={onSubmit} type="submit" flex={1}>
              Selecionar
            </Button>
          </ButtonGroup>
        </Flex>
      </PageWithoutBackground>
    </>
  );
};
